.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
}

.carousel_own {
    background-color: white;
    height: auto;
    width: 50%;
    /*margin-left: 50%;*/
}

.selected, bg-white {
   background-color: inherit!important;
}

