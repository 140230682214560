.carousel_custom {
    display: flex;
    margin-top: 70px;
    width: 100%;
    .first {
      height: 130%;
      width: 100%;
      margin-top: -100px;
    }
    .second{

    }
    .third {
      width: 90%;

    }
    .text{
      width: 40%;
      height: 70%;
    }
    .slider {
      width: 50%;
      margin-left: 50%;
    }
    img {
       height: 100%;
       width: 90%;
    }
}
